import React from "react"
import { useTranslation, usePageContext } from "@3nvi/gatsby-theme-intl"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { Link as GatsbyLink, graphql } from "gatsby"
import Slider from "react-slick"

import Layout from "../components/layout"

const IndexPage = ({data}) => {
  const { lang } = usePageContext()
  const { t } = useTranslation()
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    nextArrow: <NextArrow className='nextArrow' />,
    prevArrow: <PrevArrow className='prevArrow' />
  }
  return (
    <Layout>
      <Slider {...settings} className='mb-4'>
        <GatsbyImage image={data.image1.childImageSharp.gatsbyImageData} alt="Slide 1"></GatsbyImage>
        <GatsbyImage image={data.image2.childImageSharp.gatsbyImageData} alt="Slide 2"></GatsbyImage>
        <GatsbyImage image={data.image3.childImageSharp.gatsbyImageData} alt="Slide 3"></GatsbyImage>
      </Slider>

      <div class="flex flex-row justify-around">
        <GatsbyLink to={`/${lang}/standard`} className="w-auto border border-grey mx-1">
          <StaticImage src="../images/home-standard.jpeg" alt="Standard" />
          <div class="w-full bg-red text-white text-center text-xl">{t("home.first")}</div>
        </GatsbyLink>
        <GatsbyLink to={`/${lang}/big-forks`} className="w-auto border border-grey mx-1">
          <StaticImage src="../images/home-big-forks.jpeg" alt="Big Forks" />
          <div class="w-full bg-red text-white text-center text-xl">{t("home.second")}</div>
        </GatsbyLink>
        <GatsbyLink to={`/${lang}/${t("home.third-link")}`} className="w-auto border border-grey mx-1">
          <img src={t("home.third-image")} alt="Custom Build / Accessories"/>
          <div class="w-full bg-red text-white text-center text-xl">{t("home.third")}</div>
        </GatsbyLink>
        <GatsbyLink to={`/${lang}/construction`} className="w-auto border border-grey mx-1">
          <img src={t("home.fourth-image")} alt="Construction" />
          <div class="w-full bg-red text-white text-center text-xl">{t("home.fourth")}</div>
        </GatsbyLink>
      </div>

    </Layout>
  )
}

export default IndexPage

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export const pageQuery = graphql`
  query($lang: String) {
    image1: file(sourceInstanceName: {eq: $lang}, name: {eq: "home-banner-01"}) {
      id
        childImageSharp {
          gatsbyImageData 
        }   
    }
    image2: file(sourceInstanceName: {eq: $lang}, name: {eq: "home-banner-02"}) {
      id
        childImageSharp {
          gatsbyImageData 
        }   
    }
    image3: file(sourceInstanceName: {eq: $lang}, name: {eq: "home-banner-03"}) {
      id
        childImageSharp {
          gatsbyImageData 
        }   
    }
  }
`